import React, { Fragment } from 'react'

// Graphql
import { graphql } from 'gatsby'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Components
import NotFound from '@components/NotFound'
import PostList from '@components/PostList'
import SEO from '@components/SEO'

const NotFoundPage = ({ data }) => {
  const {
    allMdx: { edges }
  } = data
  return (
    <Fragment>
      <SEO
        title='Maalesef böyle bir sayfa bulunamadı.'
        description='Maalesef böyle bir sayfa bulunamadı.'
        keywords='404, Not Found, Sayfa Bulunamadı'
      />
      <MainLayout>
        <div className='main__content'>
          <NotFound />
        </div>
        <div className='main__content'>
          <PostList edges={edges} title='Son Eklenenler' />
        </div>
      </MainLayout>
    </Fragment>
  )
}

export default NotFoundPage

export const notFoundQuery = graphql`
  query notFoundQuery {
    allMdx(sort: { order: DESC, fields: [frontmatter___createdAt] }, limit: 5) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            createdAt(formatString: "DD MMMM YYYY", locale: "tr")
            title
            tags
          }
        }
      }
    }
  }
`
