import React from 'react'

// Gatbsy
import { Link } from 'gatsby'

const NotFound = () => (
  <div className='not-found'>
    <h1 className='not-found__title'>404</h1>
    <p className='not-found__content'>
      Aradığınız sayfa maalesef bulunamadı. <Link to='/'>Anasayfa</Link>'ya dönebilir veya aşağıdan son eklenen
      içeriklere ulaşabilirsiniz.
    </p>
  </div>
)

export default NotFound
